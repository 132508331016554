<template>
  <v-container fill-height>
    <v-row align="start">
      <v-col lg="6" offset-lg="3" v-for="n in news" :key="n.id">
        <card-letter :data="n" view="admin" />
      </v-col>
    </v-row>
    <v-btn class="mb-10" color="success" fab bottom absolute right @click="newsletterDialog = true;">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog fullscreen v-model="newsletterDialog">
      <v-card>
        <v-card-title>
          Adicionar notícia
          <v-spacer />
          <v-btn icon @click="newsletterDialog = false;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- {{newsletter}} -->
          <v-text-field filled
            v-model="newsletter.title"
            label="Título"/>
          <v-textarea filled
            v-model="newsletter.body"
            label="Corpo"/>
          <v-file-input filled
            v-model="newsletter.image"
            label="Imagem"
            truncate-length="15"
            show-size
            accept="image/*"
            prepend-icon="photo_camera"
            @change="fileChanged" />
          <v-img
            :lazy-src="newsletter.imageStr"
            max-width="600"
            max-height="200"
            :src="newsletter.imageStr" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error">
            Cancelar
          </v-btn>
          <v-btn :loading="saveLoading" color="success" @click="save(newsletter)">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          icon
          v-bind="attrs"
          @click="snackbar = false">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import purge from '@/mixins/purge'
import CardLetter from '@/components/newsletter/CardLetter'
export default {
  components: {
    CardLetter
  },
  created() {
    this.getNews();
  },
  data () {
    return {
      newsletterDialog: false,
      saveLoading: false,
      snackText: '',
      snackbar: false,
      newsletter: {
        title: '',
        body: '',
        image: null,
        imageStr: ''
      },
      news: []
    }
  },
  methods: {
    save(nletter) {
      const letter = this.purge(nletter);
      const method = !letter.id ? axios.post : axios.put;
      const route = !letter.id ? 'v1/admin/newsletter' : `v1/admin/newsletter/${nletter.id}`
      delete letter.id;
      delete letter.image;
      this.saveLoading = true;
      method(route, letter).then(
        (response) => {
          this.saveLoading = false;
          this.snackText = !nletter.id ? 'Notícia adicionada!' : 'Notícia alterada';
          this.snackbar = true;
          this.newsletterDialog = false;
          this.getNews();
        },
        (error) => {

        }
      )
    },
    getNews() {
      axios.get('v1/admin/newsletter').then(
        (response) => {
          this.news = response.data.news;
          console.log(this.news);
        }
      )
    },
    fileChanged(v) {
      this.getBase64(v).then(
        data => {
          this.newsletter.imageStr = data;
        }
      );
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  },
  mixins: [purge]
}
</script>

<style>

</style>