<template>
  <v-form v-model="form">
    <v-container class="px-0">
        <v-row>
          <v-col class="py-0" cols="6">
            <v-text-field
              label="CEP"
              filled required
              v-model="cep"
              :rules="rules.cep"
              v-mask="'#####-###'" />
          </v-col>
          <v-col class="py-0" cols="6">
            <v-text-field
              label="Bairro"
              filled required
              v-model="personData.address.bairro"
              :rules="rules.bairro"/>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-text-field
              label="Logradouro"
              filled required
              v-model="personData.address.logradouro"
              :rules="rules.logradouro"/>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-text-field
              label="Número"
              filled required
              v-model="personData.address.numero"
              :rules="rules.numero"/>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-text-field
              label="Complemento"
              filled required
              v-model="personData.address.complemento" />
          </v-col>
          <v-col class="py-0" cols="6">
            <v-text-field
              label="Cidade"
              filled required
              v-model="personData.address.cidade"
              :rules="rules.cidade"/>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-text-field
              label="Estado"
              filled required
              v-model="personData.address.uf"
              :rules="rules.uf"/>
          </v-col>
        </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from 'axios';
import unmask from '@/mixins/unmask';
export default {
  props: {
    personData: Object
  },
  data() {
    return {
      form: false,
      cep: '',
      rules: {
        cep: [
          v => !!v || 'CEP é obrigatório'
        ],
        logradouro: [
          v => !!v || 'Logradouro é obrigatório'
        ],
        bairro: [
          v => !!v || 'Bairro é obrigatório'
        ],
        numero: [
          v => !!v || 'Número é obrigatório'
        ],
        cidade: [
          v => !!v || 'Cidade é obrigatório'
        ],
        uf: [
          v => !!v || 'Estado é obrigatório'
        ]
    },

    }
  },
  methods: {
  },
  computed: {
    computedValid () {
      return this.form;
    },
  },
  watch: {
    cep(cep) {
      this.personData.address.cep = this.unmask(cep, '#####-####');
      if (this.personData.address.cep.length === 8)
        axios.get(`https://viacep.com.br/ws/${cep}/json/unicode`)
          .then(
            (response) => {
              if (!response.data.erro) {
                // this.form.address = response.data;
                this.personData.address.cidade = response.data.localidade;
                this.personData.address.logradouro = response.data.logradouro;
                this.personData.address.complemento = response.data.complemento;
                this.personData.address.bairro = response.data.bairro;
                this.personData.address.cidade = response.data.localidade;
                this.personData.address.uf = response.data.uf;
              }
            }
          )
    },
    computedValid(val) {
      this.$emit('valid', val);
    }
  },
  mixins: [unmask]
}
</script>

<style>

</style>