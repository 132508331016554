<template>
  <v-container>
    <v-card :key="data.id">
      <v-img 
        :src="data.imageStr"
        max-width="1000"
        max-height="250"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        class="white--text align-end">
      <v-card-title>
          {{data.title}}
      </v-card-title>
      </v-img>
      <v-card-text v-show="view === 'investor'" style="white-space: pre-line;">
        {{data.body}}
      </v-card-text>
      <v-card-actions v-if="view === 'admin'">
      <v-spacer />
      <v-btn @click="dialog=true;">
        Editar
      </v-btn>
      <v-btn color="error">
        Excluir
      </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog fullscreen v-model="dialog">
      <v-card>
        <v-card-title>
          Editar notícia
          <v-spacer />
          <v-btn icon @click="dialog = false;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- {{newsletter}} -->
          <v-text-field filled
            v-model="data.title"
            label="Título"/>
          <v-textarea filled
            v-model="data.body"
            label="Corpo"/>
          <v-file-input filled
            v-model="data.image"
            label="Imagem"
            truncate-length="15"
            show-size
            accept="image/*"
            prepend-icon="photo_camera"
            @change="fileChanged" />
          <v-img
            :lazy-src="data.imageStr"
            max-width="600"
            max-height="200"
            :src="data.imageStr" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error">
            Cancelar
          </v-btn>
          <v-btn :loading="saveLoading" color="success" @click="save(data)">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import purge from '@/mixins/purge'
export default {
  created () {
  },
  props: {
    data: Object,
    view: String,
  },
  data() {
    return {
      dialog: false,
      saveLoading: false,
    }
  },
  methods: {
    save(nletter) {
      const letter = this.purge(nletter);
      const method = !letter.id ? axios.post : axios.put;
      const route = !letter.id ? 'v1/admin/newsletter' : `v1/admin/newsletter/${nletter.id}`
      delete letter.id;
      delete letter.image;
      this.saveLoading = true;
      method(route, letter).then(
        (response) => {
          this.saveLoading = false;
          this.snackText = !nletter.id ? 'Notícia adicionada!' : 'Notícia alterada';
          this.snackbar = true;
          this.dialog = false;
        },
        (error) => {

        }
      )
    },
    fileChanged(v) {
      this.getBase64(v).then(
        data => {
          this.data.imageStr = data;
        }
      );
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  },
  mixins: [purge]
}
</script>

<style>

</style>