import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';
import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset'
// import { preset } from 'vue-cli-plugin-vuetify-preset-owl/preset'
// import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'
// import { preset } from 'vue-cli-plugin-vuetify-preset-basil/preset'

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconfont: 'md',
  },
});
