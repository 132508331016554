<template>
  <v-card>
    <v-card-title class="pb-0">
      Recuperar senha
    </v-card-title>
    <v-card-text class="pb-0">
      <v-text-field filled
        v-model="email"
        label="Email pra recuperação" 
        v-show="!msgText"/>
        <div v-show="msgText">
          Um email com o link para a recuperação da conta foi enviado para seu email.
          Verifique sua conta.
        </div>
    </v-card-text>
    <v-card-actions>
      <v-btn v-show="!msgText" block color="info" :loading="loading" @click="requestNewPasswd">
        Recuperar
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="snackbar">
      email não foi encontrado
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          icon
          v-bind="attrs"
          @click="snackbar = false">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      email: '',
      snackbar: false,
      loading: !true,
      msgText: false,
    }
  },
  methods: {
    requestNewPasswd() {
      this.loading = true;
      axios.post('api/reset', {email: this.email}).then(
        (response) => {
          this.loading = false;
          this.msgText = true;
        },
        (err) => {
          this.loading = false;
          this.snackbar = true;
        }
      )
    }
  }
}
</script>

<style>

</style>