<template>
  <v-container>
    <v-row align="start">
      <v-col>
        <v-list>
          <template v-for="(client, index) in clients">
            <v-list-item :key="client.id" @click="viewClientId(client.id)">
              <v-list-item-content>
                <v-list-item-title>
                  {{client.name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{client.cpf | cpfCnpj}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`${client.id}d`" v-if="index < clients.length - 1" />
          </template>
        </v-list>
        <v-pagination
          v-if="pLength > 1"
          v-model="page"
          :length="pLength" />
      </v-col>
    </v-row>

    <v-btn class="mb-10" color="success" fab bottom absolute right @click="createNewClient">
      <v-icon>add</v-icon>
    </v-btn>
    
    <v-dialog max-width="900" v-model="newClientDialog" :persistent="isEditing">
      <v-card>
        <v-card-title>
          {{isEditing ? 'Editar' : 'Adicionar'}} Cliente
          <v-spacer />
          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- {{selectedClient}} <hr />
        {{newClient}} -->
         <v-card-text>
           <v-container>
              <v-text-field filled class="pb-0"
                v-model="newClient.extra.name"
                label="Nome"/>
             <v-row class="pt-0">
               <v-col cols="6">
                <v-text-field filled
                  v-model="phone" v-if="!isEditing"
                  label="Telefone/WhatsApp"
                  v-mask="'(##) #####-####'"/>
               </v-col>
               <v-col cols="6">
                <v-text-field filled
                  v-model="username"
                  label="CPF" v-if="!isEditing"
                  v-mask="'###.###.###-##'"/>
               </v-col>
             </v-row>
              <v-text-field filled
                v-model="newClient.email"
                label="Email"/>
              <v-text-field filled
                v-model="newClient.password"
                label="Senha"/>
              <address-info :person-data="newClient.extra" />
              <v-select
                v-model="newClient.extra.blocks"
                :items="blocks"
                item-text="name"
                item-value="id"
                label="Quadras permitidos"
                multiple filled>
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="toggleAll">
                    <v-list-item-action>
                      <v-checkbox
                        v-model="selectedAllBlocks"
                        :indeterminate="newClient.extra.blocks.length > 0 && newClient.extra.blocks.length < blocks.length"/>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Selecionar Todos
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
           </v-container>
         </v-card-text>
         <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="closeDialog">
            Cancelar
          </v-btn>
          <v-btn v-if="!isEditing" :loading="saveLoading" color="success" @click="save(newClient)">
            Salvar
          </v-btn>
          <v-btn v-else :loading="saveLoading" color="success" @click="save(newClient, selectedClient.id)">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog fullscreen v-model="viewClientDialog">
      <v-card>
        <v-card-title>
          Informações do cliente
          <v-spacer />
          <v-btn text @click="editClient">
            Editar
            <v-icon right>edit</v-icon>
          </v-btn>
          <v-btn icon @click="viewClientDialog = false;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="4">
                <v-card outlined>
                  <v-card-title class="pb-0">
                    Dados basicos
                  </v-card-title>
                  <v-list dense>
                    <v-list-item>
                      Nome: {{selectedClient.name}}
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item>
                      CPF: {{selectedClient.cpf | cpfCnpj}}
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item>
                      Telefone: {{selectedClient.phone | phone}}
                      <v-spacer />
                      <v-btn v-if="selectedClient.whatsapp" text color="green" @click="openWpp(selectedClient.whatsapp)">
                        Chamar no WhatsApp
                      </v-btn>
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item>
                      E-mail: {{selectedClient.email}}
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item>
                      Quadras permitidos: 
                      <v-chip small class="mx-1 mt-1" v-for="block in selectedClient.blocks" :key="block.id">
                        {{block.name}}
                      </v-chip>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="4" v-if="selectedClient.address !== undefined">
                <v-card outlined>
                  <v-card-title class="pb-0">
                    Endereço
                  </v-card-title>
                  <v-list dense>
                    <v-list-item>
                      CEP: {{selectedClient.address.cep}}
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item>
                      Bairro: {{selectedClient.address.bairro}}
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item>
                      Logradouro: {{selectedClient.address.logradouro}}
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item>
                      Número: {{selectedClient.address.numero}}
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item v-if="selectedClient.address.complemento !== ''">
                      Complemento: {{selectedClient.address.complement}}
                    </v-list-item>
                    <v-divider class="mx-4" v-if="selectedClient.address.complemento !== ''"/>
                    <v-list-item>
                      Cidade: {{selectedClient.address.cidade}}
                    </v-list-item>
                    <v-divider class="mx-4" />
                    <v-list-item>
                      UF: {{selectedClient.address.uf}}
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="4" v-if="selectedClient.batches.length > 0">
                <v-card outlined>
                  <v-card-title class="pb-0">
                    Lotes
                  </v-card-title>
                  <v-list>
                    <template v-for="(batch, index) in selectedClient.batches">
                      <v-list-item :key="batch.id">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{batch.block.name}}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Lote {{batch.number}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mx-4" :key="`${batch.id}d`" v-if="index < clients.length - 1" />
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    
  </v-container>
</template>



<script>
import axios from 'axios';
import purge from '@/mixins/purge'
import AddressInfo from '@/components/register/AddressInfo'
import unmask from '@/mixins/unmask';
import { bus } from "@/main";
export default {
  components: {
    AddressInfo
  },
  created() {
    this.getClients();
    this.getBlocks();
  },
  data() {
    return {
      pLength: 2,
      page: 1,
      newClientDialog: false,
      viewClientDialog: false,
      isEditing: false,
      saveLoading: false,
      snackText: '',
      snackbar: false,
      phone: '',
      username: '',
      selectedAllBlocks: false,
      newClient: {
        email: '',
        password: '',
        username: '',
        extra: {
          name: '',
          phone: '',
          blocks: [],
          address: {
            cep: '',
            cidade: '',
            logradouro: '',
            complemento: '',
            bairro: '',
            uf: '',
            numero: ''
          }
        }
      },
      selectedClient: {
        email: '',
        password: '',
        username: '',
        blocks: [],
        batches: [],
        address: {
          cep: '',
          cidade: '',
          logradouro: '',
          complemento: '',
          bairro: '',
          uf: '',
          numero: ''
        }
      },
      clients: [],
      blocks: []
    }
   
  },
  methods: {
    editClient() {
      this.isEditing = true;
      this.newClientDialog = true;
      this.newClient = {
        email: this.selectedClient.email,
        password: '',
        username: this.selectedClient.cpf,
        phone: this.selectedClient.phone,
        extra: {
          name: this.selectedClient.name,
          blocks:  this.selectedClient.blocks.map(b => b.id),
          address: this.selectedClient.address
        }
      };
    },
    createNewClient() {
      this.isEditing = false;
      this.newClientDialog = true;
      this.newClient = {
        email: '',
        password: '',
        username: '',
        extra: {
          name: '',
          phone: '',
          blocks: [],
          address: {
            cep: '',
            cidade: '',
            logradouro: '',
            complemento: '',
            bairro: '',
            uf: '',
            numero: ''
          }
        }
      }
    },
    save(nclient, id) {
      this.saveLoading = true;
      console.log(id);
      if (id === undefined)
        axios.post(`/v1/admin/client`, nclient).then(
          (response) => {
            this.saveLoading = false;
            this.snackText = !nclient.id ? 'Cliente Adicionado!' : 'Cliente Alterado';
            this.snackbar = true;
            this.newClientDialog = false;
            this.getClients();
          },
          (error) => {

          }
        )
      else
        axios.put(`/v1/admin/client/${id}`, nclient).then(
          (response) => {
            this.saveLoading = false;
            this.snackText = !nclient.id ? 'Cliente Adicionado!' : 'Cliente Alterado';
            this.snackbar = true;
            this.newClientDialog = false;
            this.viewClientDialog = false;
            this.getClients();
          },
          (error) => {

          }
        )
    },
    toggleAll() {
      this.$nextTick(() => {
        if (this.newClient.extra.blocks.length === this.blocks.length) {
          this.newClient.extra.blocks = []
        } else {
          this.newClient.extra.blocks = this.blocks.slice()
        }
      })
    },
    getBlocks() {
      bus.$emit("loading", { loading: true });
      axios.get('/v1/block').then(
        (response) => {
          bus.$emit("loading", { loading: false });
          this.blocks = response.data.blocks;
        }
      )
    },
    openWpp(link){
      window.open(link, "_blank");
    },
    closeDialog() {
      this.username = '';
      this.phone = '';
      this.newClient = {
        email: '',
        password: '',
        username: '',
        extra: {
          name: '',
          phone: '',
          blocks: [],
          address: {
            cep: '',
            cidade: '',
            logradouro: '',
            complemento: '',
            bairro: '',
            uf: '',
            numero: ''
          }
        }
      }
      this.newClientDialog = false;
    },
    getClients() {
      bus.$emit("loading", { loading: true });
      axios.get(`/v1/admin/client?p=${this.page}`).then(
        (response) => {
          bus.$emit("loading", { loading: false });
          this.clients = response.data.clients;
          this.selectedClient = this.clients[0];
          this.pLength = response.data.pages;
        }
      )
    },
    viewClientId(id) {
      bus.$emit("loading", { loading: true });
      axios.get(`/v1/admin/client/${id}`).then(
        (response) => {
          bus.$emit("loading", { loading: false });
          this.selectedClient = response.data;
          this.selectedClient.id = id;
          this.viewClientDialog = true;
        }
      )
    },
    fileChanged(v) {
      this.getBase64(v).then(
        data => {
          this.newsletter.imageStr = data;
        }
      );
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  },
  watch: {
    'newClient.extra.blocks': function(val) {
      this.selectedAllBlocks = this.blocks.length === val.length;
    },
    page(val) {
      this.getClients(val);
    },
    phone(val) {
      this.newClient.extra.phone = this.unmask(val, '(##) #####-####');
    },
    username(val){
      this.newClient.username = this.unmask(val, '###.###.###-##');
    },
  },
  mixins: [purge, unmask]
}
</script>

<style>

</style>