import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/auth/Login.vue'
import Confirmed from '@/views/auth/Confirmed.vue'
import ResetPassword from '@/views/auth/ResetPassword'
import OperatorIndex from '@/views/operator/Index'
import AdminIndex from '@/views/admin/Index'
import AdminHome from '@/views/admin/Home'
import AdminBlocks from '@/views/admin/Blocks'
import AdminClients from '@/views/admin/Clients'
import AdminNewsletter from '@/views/admin/Newsletter'
import ClientIndex from '@/views/client/Index'
// import ClientHome from '@/views/client/Home'
import ClientAvailable from '@/views/client/Available'

import AdminIntents from '@/views/admin/Intents'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/confirmed',
    name: 'Confirmed',
    component: Confirmed
  },
  {
    path: '/confirm-error',
    name: 'ConfirmedError',
    component: Confirmed
  },
  {
    path: '/admin',
    name: 'AdminIndex',
    component: AdminIndex,
    children: [
      {
        path: '/admin/home',
        name: 'AdminHome',
        component: AdminHome,
      },
      {
        path: '/admin/blocks',
        name: 'AdminBlocks',
        component: AdminBlocks,
      },
      {
        path: '/admin/clients',
        name: 'AdminClients',
        component: AdminClients,
      },
      {
        path: '/admin/intents',
        name: 'AdminIntents',
        component: AdminIntents,
      },
      {
        path: '/admin/newsletter',
        name: 'AdminNewsletter',
        component: AdminNewsletter
      }
    ]
  },
  {
    path: '/operator',
    name: 'OperatorIndex',
    component: OperatorIndex,
    children: [
      {
        path: '/operator/home',
        name: 'OperatorHome',
        component: AdminHome,
      },
      {
        path: '/operator/clients',
        name: 'OperatorClients',
        component: AdminClients,
      },
      {
        path: '/operator/intents',
        name: 'OperatorIntents',
        component: AdminIntents,
      },
    ]
  },
  {
    path: '/client',
    name: 'ClientIndex',
    component: ClientIndex,
    children: [
      {
        path: '/client/home',
        name: 'ClientHome',
        component: ClientAvailable,
      },
      {
        path: '/client/available',
        name: 'ClientAvailable',
        component: ClientAvailable,
      },
    ]
  }  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
