<template>
  <v-container align="start">
    <v-row>
      <v-col cols="12">
      </v-col>
      <v-col>
        <v-list>
          <template v-for="request in requests">
            <v-list-item two-line :key="request.id" @click="selectRequest(request)">
              <v-list-item-content>
                <v-list-item-title>{{request.value | brl}}</v-list-item-title>
                <v-list-item-subtitle>{{request.investor.name}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
// import { bus } from "@/main";
import simBanks from '@/mixins/simBanks';

export default {
  components: {
  },
  created () {
  },
  data(){
    return {
      snackbar: false,
      actionText: '',
      actionDialog: false,
      requests: [],
    };
  },
  methods: {

  },
  mixins: [simBanks]
}
</script>

<style>

</style>