<template>
  <v-container>
    <v-row align="start">
      <v-col>
        <v-list>
          <template v-for="(block, index) in blocks">
            <v-list-item two-line @click="viewBlockId(block.id)" :key="block.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{block.name}}
                </v-list-item-title>
                <v-list-item-subtitle>{{block.quantity}} lotes</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`${block.id}d`" v-if="index < blocks.length - 1" />
          </template>
        </v-list>
      </v-col>
    </v-row>
     <v-btn class="mb-10" color="success" fab bottom absolute right @click="newBlockDialog = true;">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog max-width="500" v-model="newBlockDialog">
      <v-card>
        <v-card-title>
          Adicionar Quadra
          <v-spacer />
          <v-btn icon @click="newBlockDialog = false;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
         <v-card-text>
            <v-text-field filled
            v-model="newBlock.name"
            label="Quadra"/>
            <v-text-field filled
            v-model="newBlock.quantity"
            label="Quantidade"/>
         </v-card-text>
         <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="newBlockDialog = false;">
            Cancelar
          </v-btn>
          <v-btn :loading="saveLoading" color="success" @click="save(newBlock)">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog fullscreen v-model="viewBlockDialog">
      <v-card>
        <v-card-title>
          Quadra {{selectedBlock.name}}
          <v-spacer />
          <v-btn icon @click="viewBlockDialog = false;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-subheader>Lotes ocupados</v-subheader>
            <template v-for="(batch, index) in selectedBlock.batches">
              <v-list-item two-line :key="batch.id" v-if="batch.owner !== null">
                <v-list-item-content>
                  <v-list-item-title>
                    Lote de n {{batch.number}}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="batch.owner === null">Sem proprietário</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>{{batch.owner.name}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon color="red" @click="selectBatch(batch)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`${batch.id}d`" v-if="index < selectedBlock.batches.length - 1 && batch.owner !== null"/>
            </template>
            <v-subheader>Lotes em aberto</v-subheader>
            <template v-for="(batch, index) in selectedBlock.batches">
              <v-list-item two-line :key="batch.id" v-if="batch.owner === null">
                <v-list-item-content>
                  <v-list-item-title>
                    Lote de n {{batch.number}}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="batch.owner === null">Sem proprietário</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>{{batch.owner.name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="`${batch.id}d`" v-if="index < selectedBlock.batches.length - 1 && batch.owner === null"/>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="removeDialog">
      <v-card>
        <v-card-title>
          Remover proprietário
          <v-spacer />
          <v-btn icon @click="removeDialog = false;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Cliente: {{selectedBatch.owner.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Lote: {{selectedBatch.number}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn @click="removeDialog = false;" color="error" block>
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn :loading="removeLoading" @click="removeBatch(selectedBatch.id)" color="success" block>
                  Remover
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          icon
          v-bind="attrs"
          @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>



<script>
import axios from 'axios';
import purge from '@/mixins/purge'
import { bus } from "@/main";
export default {
  components: {
  },
  created() {
    this.getBlocks();
  },
  data() {
    return {
      snackbar: false,
      snackMsg: '',
      newBlockDialog: false,
      viewBlockDialog: false,
      removeDialog: false,
      removeLoading: false,
      saveLoading: false,
      snackText: '',
      snackbar: false,
      newBlock: {
        name: '',
        quantity: null,
      },
      selectedBatch:{
        number: 1,
        owner: {
          name: '',
        }
      },
      selectedBlock: {
        name: '',
        quantity: null
      },
      blocks: []
    }
   
  },
  methods: {
    save(nblock) {
      const block = this.purge(nblock);
      const method = !block.id ? axios.post : axios.put;
      // const route = !block.id ? '/v1/block' : `/v1/block/${nletter.id}`
      delete block.id;
      block.quantity = parseInt(block.quantity);

      this.saveLoading = true;
      method(`/v1/block`, block).then(
        (response) => {
          this.saveLoading = false;
          this.snackText = !nblock.id ? 'Quadra Adicionado!' : 'Quadra Alterado';
          this.snackbar = true;
          this.newBlockDialog = false;
          this.getBlocks();
        },
        (error) => {

        }
      )
    },
    selectBatch(batch) {
      this.selectedBatch = batch;
      console.log(batch);
      this.removeDialog = true;
    },
    getBlocks() {
      bus.$emit("loading", { loading: true });
      axios.get('/v1/block').then(
        (response) => {
          bus.$emit("loading", { loading: false });
          this.blocks = response.data.blocks;
        }
      )
    },
    removeBatch(batchId) {
      // bus.$emit("loading", { loading: true });
      this.removeLoading = true;
      axios.delete(`/v1/block/batch/${batchId}`).then(
        (response) => {
          this.removeLoading = false;
          this.removeDialog = false;
          this.viewBlockDialog = false;
          this.snackMsg = response.data.msg;
          this.snackbar = true;
        }
      )
    },
    viewBlockId(id) {
      bus.$emit("loading", { loading: true });
      axios.get(`/v1/block/${id}`).then(
        (response) => {
          bus.$emit("loading", { loading: false });
          this.selectedBlock = response.data;
          this.viewBlockDialog = true;
        }
      )
    },
  },
 mixins: [purge]
}
</script>

<style>

</style>