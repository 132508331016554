<template>
  <v-app id="inspire">
    <v-navigation-drawer
      :permanent="$vuetify.breakpoint.mdAndUp"
      v-model="drawer" app>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{user.name}}
            </v-list-item-title>
            <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list nav dense>
        <template v-for="item in menu">
          <v-list-item :key="item.title" link @click="goTo(item.route)">
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item link @click="passwordDialog = true">
          <v-list-item-icon>
            <v-icon>password</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mudar senha</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>close</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{titleBar}}</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-dialog v-model="passwordDialog" max-width="400">
      <v-card>
        <v-card-title>
          Mudar senha
        </v-card-title>
        <v-card-text class="py-0">
          <v-text-field outlined
            :rules="rules.old_password"
            v-model="oldPassword"
            type="password"
            label="Antiga senha" />
          <v-text-field outlined
            :rules="rules.password"
            v-model="password"
            type="password"
            label="Nova senha" />
          <v-text-field outlined
            :rules="rules.re_password"
            v-model="re_password"
            type="password"
            label="Repita a senha" />
        </v-card-text>
        <v-card-actions class="pb-4 pt-0">
          <v-btn depressed color="accent" @click="newPassword(oldPassword, password, re_password)">
            Alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          icon
          v-bind="attrs"
          @click="snackbar = false">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
      class="mt-0">
      <v-card
        color="black"
        dark>
        <v-card-text>
          Aguarde
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from 'axios'
import goTo from '@/mixins/goTo'
import { bus } from "@/main";
export default {
  props: {
    menu: Array,
    titleBar: String
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user'));
    bus.$on("loading", (payload) => {
      this.loading = payload.loading;
    });
  },
  data() {
    return {
      drawer: null,
      passwordDialog: false,
      snackText: '',
      snackbar: '',
      oldPassword: '',
      password: '',
      re_password: '',
      loading: false,
      rules: {
        old_password: [
          v => !!v || 'Senha anterior é obrigatório',
        ],
        password: [
          v => !!v || 'Senha é obrigatório',
          v => v != undefined && v.length >= 8 || 'Senha deve conter um nínimo de 8 caracteres.'
        ],
        re_password: [
          v => !!v || 'Repita a senha',
          v => v != undefined && this.password == v || 'Senha não confere'
        ]
      },
      user: {
        name: 'Lucas Cunha Farias',
        email: 'cunha.ladm@outlook.com'
      }
    }
  },
  methods: {
    newPassword(oldPasswd, newPasswd, rePasswd) {
      this.loading = true;
      axios.post('api/change?include_auth_token', {
        'password': oldPasswd,
        'new_password': newPasswd,
        'new_password_confirm': rePasswd
        }).then(
        (response) => {
          const user = response.data.response.user;
          localStorage.setItem('auth_token', user.authentication_token);
          localStorage.setItem('user', JSON.stringify(user));
          window.axios.defaults.headers.common['Authentication-Token'] = `${user.authentication_token}`;
          this.snackText = 'Sua senha foi alterada com sucesso.';
          this.snackText = true;
          this.passwordDialog = false;
          this.loading = false;
        }
      )
    },
    logout() {
      axios.get('api/logout').then(
        () => {
          localStorage.clear('user');
          localStorage.clear('auth_token');
          localStorage.clear('csrf_token');
          delete window.axios.defaults.headers.common['Authentication-Token'];
          this.goTo('/');
        },
        () => {
          localStorage.clear('user');
          localStorage.clear('auth_token');
          localStorage.clear('csrf_token');
          delete window.axios.defaults.headers.common['Authentication-Token'];
          this.goTo('/');
        }
      )
    }
  },
  mixins: [goTo]
}
</script>

<style>

</style>