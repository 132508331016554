<template>
  <v-container>
    <v-list>
      <template v-for="(intent, index) in intents">
        <v-list-item two-line :key="intent.key" @click="viewIntent(intent)">
          <v-list-item-content>
            <v-list-item-title>
              {{intent.client.name}}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{intent.batch.block.name}} / Lote {{intent.batch.number}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="`${intent.id}d`" v-if="index < intents.length - 1" />
      </template>
    </v-list>
    <v-dialog max-width="600" v-model="intentDialog">
      <v-card>
        <v-card-title>
          Solicitação de propriedade
          <v-spacer />
          <v-btn icon @click="intentDialog = false;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Cliente: {{selectedIntent.client.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Quadra: {{selectedIntent.batch.block.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Lote: {{selectedIntent.batch.number}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn :loading="disapproveLoading" @click="changeIntent(selectedIntent.id, 3)" color="error" block>
                  Recusar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn :loading="approveLoading" @click="changeIntent(selectedIntent.id, 2)" color="success" block>
                  Aprovar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          icon
          v-bind="attrs"
          @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import { bus } from "@/main";
export default {
  created() {
    this.getIntents();
  },
  data() {
    return {
      intents: [],
      intentDialog: false,
      snackbar: false,
      snackMsg: '',
      approveLoading: false,
      disapproveLoading: false,
      selectedIntent: {
        batch: {
          block: {
            id: null,
            name: null,
            quantity: null
          },
          id: null,
          number: null
        },
        client: {
          id: null,
          name: null
        },
        id: null
      }
    }
  },
  methods: {
    getIntents() {
      bus.$emit("loading", { loading: true });
      axios.get('v1/admin/intent').then(
        (response) => {
        bus.$emit("loading", { loading: false });
          this.intents = response.data.intents
        }
      )
    },
    changeIntent(id, status) {
      this.approveLoading = status === 2 ? true : false;
      this.disapproveLoading = status === 3 ? true : false;
      axios.put(`v1/admin/intent/${id}`, {'status': status}).then(
        (response) => {
          this.snackMsg = response.data.msg;
          this.intentDialog = false;
          this.approveLoading = false;
          this.disapproveLoading = false;
          this.snackbar = true;
          this.getIntents();
        }
      )
    },
    viewIntent(val) {
      this.selectedIntent = val;
      this.intentDialog = true;
    }
  }
}
</script>

<style>

</style>