<template>
  <v-container align="center">
    <v-dialog max-width="600px" v-model="dialog" persistent>
      <v-card>
        <v-card-title class="pb-0 pt-3">
          Parabéns!
        </v-card-title>
        <v-card-text class="py-0">
          Sua conta acaba de ser confirmada. Clique abaixo para fazer o login e começar a investir conosco.
        </v-card-text>
        <v-card-actions>
          <v-btn block class="mb-2" color="accent" @click="goTo('/')">
            Voltar ao login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import goTo from '@/mixins/goTo'
export default {
  data() {
    return {
      dialog: true,
    }
  },
  methods: {},
  mixins: [goTo]
}
</script>

<style>

</style>