import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask';
import axios from 'axios';
import moment from 'moment';

Vue.use(VueMask);

Vue.filter('fullDate', (data) => {
  if (data)
    if (data.indexOf('/') === -1)
      return moment(String(data)).format('DD/MM/YYYY');
    else
      return data;
});

Vue.filter('dayMonth', (data) => {
  if (data)
    if (data.indexOf('/') === -1)
      return moment(String(data)).format('DD/MM');
    else
      return data;
});

Vue.filter('brl', (data) => {
  let val = (data / 1).toFixed(2).replace('.', ',');
  return `R$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
});

Vue.filter('cpfCnpj', (data) => {
  if (data)
    if (data.length === 11)
      return `${data.substring(0, 3)}.${data.substring(3, 6)}.${data.substring(6, 9)}-${data.substring(9, 11)}`;
    else if (data.length === 14)
      return `${data.substring(0, 2)}.${data.substring(2, 5)}.${data.substring(5, 8)}/${data.substring(8, 12)}-${data.substring(12, 14)}`
});
Vue.filter('phone', (data) => {
  if (data)
      return `(${data.substring(0, 2)}) ${data.substring(3, 4)} ${data.substring(3, 7)}-${data.substring(6, 10)}`;
});

Vue.config.productionTip = false

export const bus = new Vue();

window.axios = axios;
const token = localStorage.getItem('auth_token');
const csrf = localStorage.getItem('csrf_token');

if (token)
  window.axios.defaults.headers.common['Authentication-Token'] = token;

if (csrf) {
  window.axios.defaults.headers.common['X-CSRF-Token'] = csrf;
  window.axios.defaults.headers.common['X-XSRF-Token'] = csrf;
}
  
window.axios.defaults.baseURL = window.location.host.indexOf('localhost') > -1 ? `http://localhost:5010` : 'https://api.harpiaresidencial.com/';
window.axios.defaults.withCredentials = true;


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
