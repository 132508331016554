<template>
  <v-container>
    <v-row align="start">
      <v-col cols="12" v-if="myBatches.length > 0">
        <v-card color="info">
          <v-card-title class="pb-0">Meus lotes</v-card-title>
          <v-slide-group
            v-model="batchSlide"
            class="pa-4"
            show-arrows>
            <v-slide-item
              v-for="batch in myBatches"
              :key="batch.id">
              <v-card>
                <v-card-title>
                  Quadra {{batch.block.name}} 
                </v-card-title>
                <v-card-subtitle>
                  Lote {{batch.number}}
                </v-card-subtitle>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tabs">
          <v-tab>Quadras</v-tab>
          <v-tab>Requisições</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-list>
              <template v-for="(block, index) in blocks">
                <v-list-item :key="block.id" @click="selectBlock(block)">
                  <v-list-item-content>
                    <v-list-item-title>
                      Quadra {{block.name}}
                    </v-list-item-title>
                    <v-list-item-subtitle>                  
                      {{block.batches.length}} lotes disponíveis
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="`${block.id}d`" v-if="index < blocks.length - 1" />
              </template>
            </v-list>
            <v-pagination
              v-if="pLength > 1"
              v-model="page"
              :length="pLength" />
          </v-tab-item>
          <v-tab-item>
            <v-list>
              <template v-for="(intent, index) in myIntents">
                <v-list-item :key="intent.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      Quadra {{intent.batch.block.name}} / Lote {{intent.batch.number}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{intent.status}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="`${intent.id}d`" v-if="index < myIntents.length - 1" />
              </template>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog fullscreen v-model="availableBatchesDialog">
      <v-card>
        <v-card-title>
          Quadra {{selectedBlock.name}}
          <v-spacer/>
          <v-btn outlined class="mr-2" @click="imageDialog = true">
            Ver terreno
          </v-btn>
          <v-btn icon outlined @click="availableBatchesDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-list>
          <template v-for="(batch, index) in selectedBlock.batches">
            <v-list-item :key="batch.id" @click="selectBatch(batch)">
              <v-list-item-content>
                <v-list-item-title>
                  Lote {{batch.number}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`${batch.id}d`" v-if="index < selectedBlock.batches.length - 1" />
          </template>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog fullscreen v-model="imageDialog">
      <v-card>
        <v-card-title>
          Terreno {{selectedBlock.name}}
          <v-spacer/>
          <v-btn icon outlined @click="imageDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img contain
          max-height="900px"
          :lazy-src="getImg(selectedBlock.name)"
          :src="getImg(selectedBlock.name)" />
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="intentDialog">
      <v-card>
        <v-card-title>
          Deseja escolher o Lote {{selectedBatch.number}}
        </v-card-title>
         <v-card-actions class="pt-0">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn @click="intentDialog = false" color="error" block>
                  Não
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn :loading="yesLoading" @click="doIntent(selectedBatch.id)" color="success" block>
                  Sim
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          icon
          v-bind="attrs"
          @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>



<script>
import axios from 'axios';
import purge from '@/mixins/purge'
import { bus } from "@/main";
export default {
  components: {
  },
  created() {
    this.getAvailable(1);
    this.getMyBatches();
    this.getMyIntents();
  },
  data() {
    return {
      tabs: null,
      batchSlide: null,
      myBatches: [],
      myIntents: [],
      availableBatchesDialog: false,
      newIntentDialog: false,
      viewBatchDialog: false,
      intentDialog: false,
      imageDialog: false,
      yesLoading: false,
      saveLoading: false,
      snackbar: false,
      page: 1,
      pLength: 1,
      snackMsg: 'intenção de posse registrada',
      newIntent: {
        bacth: '',
      },

      selectedBlock: {
        name: 'Q-01',
        batches: [],

      },
      blocks: [],
      selectedBatch: { 
        number: 0
        }
    }
   
  },
  methods: {
    save(nintent) {
      this.saveLoading = true;
      axios.post(`/v1/client/block/intent`, intent).then(
        (response) => {
          this.saveLoading = false;
          this.snackText = !intent.id ? 'Intenção Adicionada!' : 'Cliente Alterado';
          this.snackbar = true;
          this.newIntentDialog = false;
          this.getAvailable();
        },
        (error) => {

        }
      )
    },
    getMyBatches() {
      bus.$emit("loading", { loading: true });
      axios.get(`/v1/client/block/batch`).then(
        (response) => {
          this.myBatches = response.data.batches;
          console.log(this.myBatches);
          bus.$emit("loading", { loading: false });
        }
      )
    },
    getMyIntents() {
      bus.$emit("loading", { loading: true });
      axios.get(`/v1/client/block/intent/all`).then(
        (response) => {
          this.myIntents = response.data.intents;
          console.log(this.myBatches);
          bus.$emit("loading", { loading: false });
        }
      )
    },
    getAvailable(page) {
      bus.$emit("loading", { loading: true });
      axios.get(`/v1/client/block/available?p=${page}`).then(
        (response) => {
          this.blocks = response.data.blocks;
          this.pLength = response.data.total;
          bus.$emit("loading", { loading: false });
        }
      )
    },
    selectBlock(block){
      this.selectedBlock = block;
      this.availableBatchesDialog = true;

    },
    selectBatch(batch){
      this.selectedBatch = batch;
      this.intentDialog = true;

    },
    doIntent(batchId){
      this.yesLoading = true;
      axios.post('/v1/client/block/intent', {batch_id:batchId}).then(
        (response) => {
          this.availableBatchesDialog = false;
          this.newIntentDialog = false;
          this.viewBatchDialog = false;
          this.intentDialog = false;
          this.yesLoading = false;
          this.snackbar = true;
        }
      )
    },
    getImg(champion) {
      if (champion)
        return require(`@/assets/${champion}.png`);
      return;
    },
  },
  watch: {
    page(val) {
      this.getAvailable(val);
    }
  },
  mixins: [purge]
}
</script>

<style>

</style>