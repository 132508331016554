import axios from 'axios';
export default {
  created() {
    // this.getBanks();
  },
  data() {
    return {
      simBanks: [
        {
          "details": [
            {
              "name": "",
              "text": "2358-2",
              "title": "Agência",
              "uuid": "711f416b-4058-4b6f-9186-987b5bdd9388"
            },
            {
              "text": "210592-6",
              "title": "Conta",
              "uuid": "4e68c137-6057-465f-9921-b04162a1da11"
            },
            {
              "text": "31.107.636/0001-60",
              "title": "CNPJ",
              "uuid": "1c5c0523-af16-4b45-bed2-109d01b14127"
            },
            {
              "text": "SIM Participações Ltda",
              "title": "",
              "uuid": "f5aeeda9-7a57-45b5-b313-0ad60a9073c6"
            }
          ],
          "name": "",
          "title": "Banco do Brasil",
          "uuid": "ad3fa257-65a7-4ecd-a423-badcb5f55326"
        },
        {
          "details": [
            {
              "name": "",
              "text": "0001",
              "title": "Agência",
              "uuid": "b1c50fce-001e-4f32-a41a-736166c19830"
            },
            {
              "text": "44167180",
              "title": "Conta",
              "uuid": "9f2d87fe-6ea4-4b46-8196-6bff4880e2b5"
            },
            {
              "text": "31.107.636/0001-60",
              "title": "CNPJ",
              "uuid": "13a4efdb-79f9-4446-ab28-54817047c239"
            },
            {
              "text": "SIM Participações Ltda",
              "title": "",
              "uuid": "79b5c2f0-6aa6-410b-bed3-61dd0e22816a"
            }
          ],
          "name": "",
          "title": "Banco Inter",
          "uuid": "d0b934e0-9256-4f77-b8a3-e70d1c693222"
        },
        {
          "details": [
            {
              "name": "",
              "text": "contato@stockinvestmoney.com",
              "title": "E-mail",
              "uuid": "47971100-e699-4878-b2fe-5c5e2e89d85a"
            }
          ],
          "name": "",
          "title": "Mercado Pago",
          "uuid": "fd21bb85-a10c-4913-ad82-c2803a76dfa0"
        }
      ]
    }
  },
  methods: {
    // getBanks() {
    //   axios.get(`https://api.stockinvestmoney.com/v1.0/apoio/bank_accounts`).then(
    //     (response) => {
    //       this.simBanks = response.data.items;
    //     },
    //     () => {}
    //   )
    // },
    getBankTitle(id) {
      if (id) {
        return this.simBanks.filter(bank => bank.uuid == id)[0].title;
      }
      return '';
    }
  }
}