<template>
  <index title-bar="Área do Operador" :menu="menu" />
</template>

<script>
import axios from 'axios'
import goTo from '@/mixins/goTo'
import Index from '@/components/Index'
export default {
  components: {
    Index
  },
  data: () => ({
    menu: [
      {
        icon: 'people',
        title: 'Clientes',
        route: '/operator/clients'
      },
      {
        icon: 'pending_actions',
        title: 'Requisições de lote',
        route: '/operator/intents'
      },
    ]
  }),
  methods: {
  },
  mixins: [goTo]
}
</script>

<style>

</style>