<template>
  <index title-bar="Área do Administrador" :menu="menu" />
</template>

<script>
import axios from 'axios'
import goTo from '@/mixins/goTo'
import Index from '@/components/Index'
export default {
  components: {
    Index
  },
  data: () => ({
    menu: [
      {
        icon: 'request_quote',
        title: 'Quadras',
        route: '/admin/blocks'
      },
      {
        icon: 'people',
        title: 'Clientes',
        route: '/admin/clients'
      },
      {
        icon: 'pending_actions',
        title: 'Requisições de lote',
        route: '/admin/intents'
      },
      // {
      //   icon: 'feed',
      //   title: 'Newsletter',
      //   route: '/admin/newsletter'
      // }
    ]
  }),
  methods: {
  },
  mixins: [goTo]
}
</script>

<style>

</style>