<template>
  <v-main>
    <v-container fill-height>
      <v-row
        align="center"
        no-gutters>
        <v-col
          offset-lg="4"
          lg="4"
          align-self="center">
          <v-card
            class="pa-2"
            outlined>
            <v-card-title>
              <v-img src="@/assets/logo.png" height="150" contain/>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-text-field outlined label="E-mail ou CPF/CNPJ" v-model="login.username"/>
              <v-text-field outlined type="password" label="Senha" v-model="login.password"/>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-btn depressed color="secondary" @click="doLogin(login)">
                Entrar
              </v-btn>
              <v-btn depressed @click="newPasswdDialog = true;">
                Esqueci minha senha
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar">
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          icon
          v-bind="attrs"
          @click="snackbar = false">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog fullscreen v-model="pDialog">
      <v-card>
        <v-card-title>
          Escolha um módulo
          <v-spacer />
          <v-btn icon @click="pDialog = false">
            <v-icon large>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols-lg="6" v-for="profile in profiles" :key="profile.name">
                <v-card @click="goToProfile(profile.name)">
                  <v-list-item>
                    <v-list-item-avatar tile size="100">
                      <v-icon x-large>
                        {{profile.name === 'investor' ? 'account_circle' : 'admin_panel_settings'}}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">
                        {{profile.description}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="newPasswdDialog">
      <lost-password />
    </v-dialog>
  </v-main>
</template>

<script>
import axios from 'axios';
import goTo from '@/mixins/goTo';
import LostPassword from '@/components/auth/LostPassword'
export default {
  components: {
    LostPassword
  },
  created() {
    localStorage.clear();
    this.autoLogout();
  },
  data: () => ({
    snackbar: false,
    errorText: 'Erro de Login',
    newPasswdDialog: false,
    login: {
      username: '',
      password: '',
    },
    profiles: [
      {
        "description": "Investidor",
        "name": "investor"
      },
      {
        "description": "Administrador",
        "name": "admin"
      }
    ],
    pDialog: false
  }),
  methods: {
    getImg(champion) {
      if (champion)
        return require(`@/assets/${champion}.png`);
      return;
    },
    autoLogout() {
      axios.get('api/logout').then(() => {
          localStorage.clear('user');
          localStorage.clear('auth_token');
          localStorage.clear('csrf_token');
          delete window.axios.defaults.headers.common['Authentication-Token'];
      });
    },
    goToProfile(pName) {
      this.goTo(`/${pName}/home`);
    },
    doLogin(user) {
      axios.post('api/login?include_auth_token', user).then(
        (response) => {
          const user = response.data.response.user;
          localStorage.setItem('auth_token', user.authentication_token);
          localStorage.setItem('csrf_token', response.data.response.csrf_token);
          localStorage.setItem('user', JSON.stringify(user));
          window.axios.defaults.headers.common['Authentication-Token'] = user.authentication_token;
          window.axios.defaults.headers.common['X-CSRF-Token'] = response.data.response.csrf_token;
          window.axios.defaults.headers.common['X-XSRF-Token'] = response.data.response.csrf_token;
          console.log(user.roles.length);
          if (user.roles.length === 1) {
            this.goTo(`/${user.roles[0].name}/home`);
          } else {
            this.profiles = user.roles;
            this.pDialog = true;
          }
        }, (err) => {
          if (err.response.data.response.errors) {
            if (err.response.data.response.errors.email) {
              this.errorText = "E-mail ou CPF/CNPJ não encontrado no sistema";
            }
            if (err.response.data.response.errors.password) {
              this.errorText = "Senha inválida";
            }
          }
          this.snackbar = true;
        }
      );
    }
  },
  mixins: [goTo]
}
</script>

<style>

</style>