<template>
  <v-main>
    <v-container fill-height>
      <v-row>
        <v-col cols="12" lg="4" offset-lg="4">
          <v-card>
            <v-card-title>
              <v-btn icon class="mr-4" @click="goTo('/')">
                <v-icon>
                  arrow_back
                </v-icon>
              </v-btn>
              Alterar senha
            </v-card-title>
            <v-card-text class="pb-0">
              <v-text-field filled
                label="Nova senha"
                type="password"
                :rules="rules.password"
                v-model="password" />
              <v-text-field filled
                label="Repita a senha"
                type="password"
                :rules="rules.re_password"
                v-model="re_password" />
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-btn :loading="loading" depressed block color="info" @click="resetPassword">
                Alterar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog fullscreen v-model="pDialog">
      <v-card>
        <v-card-title>
          Escolha um módulo
          <v-spacer />
          <v-btn icon @click="pDialog = false">
            <v-icon large>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols-lg="6" v-for="profile in profiles" :key="profile.name">
                <v-card @click="goToProfile(profile.name)">
                  <v-list-item>
                    <v-list-item-avatar tile size="100">
                      <v-icon x-large>
                        {{profile.name === 'investor' ? 'account_circle' : 'admin_panel_settings'}}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">
                        {{profile.description}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar">
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          icon
          v-bind="attrs"
          @click="snackbar = false">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-main>
</template>

<script>
import axios from 'axios';
import goTo from '@/mixins/goTo';
export default {
  created () {
    console.log(this.$route.query.token);
  },
  data() {
    return {
      password: '',
      re_password: '',
      pDialog: false,
      snackbar: false,
      loading: false,
      errorText: 'Token de restauração de senha expirado.',
      token: this.$route.query.token,
      rules: {
        password: [
          v => !!v || 'Senha é obrigatório',
          v => v != undefined && v.length >= 8 || 'Senha deve conter um nínimo de 8 caracteres.'
        ],
        re_password: [
          v => !!v || 'Repita a senha',
          v => v != undefined && this.password == v || 'Senha não confere'
        ]
      },
      profiles: [
        {
          "description": "Investidor",
          "name": "investor"
        },
        {
          "description": "Administrador",
          "name": "admin"
        }
      ],
    };
  },
  methods: {
    goToProfile(pName) {
      this.goTo(`/${pName}/home`);
    },
    resetPassword() {
      this.loading = true;
      const data = {
        password: this.password,
        password_confirm: this.re_password
      }
      axios.post(`api/reset/${this.token}?include_auth_token`, data).then(
        (response) => {
          const user = response.data.response.user;
          localStorage.setItem('auth_token', user.authentication_token);
          localStorage.setItem('csrf_token', response.data.response.csrf_token);
          localStorage.setItem('user', JSON.stringify(user));
          window.axios.defaults.headers.common['Authentication-Token'] = user.authentication_token;
          window.axios.defaults.headers.common['X-CSRF-Token'] = response.data.response.csrf_token;
          window.axios.defaults.headers.common['X-XSRF-Token'] = response.data.response.csrf_token;
          console.log(user.roles.length);
          if (user.roles.length === 1) {
            if (user.roles[0].name === 'investor') {
              this.goTo('/investor/home');
            }
            this.loading = false;
          } else {
            this.profiles = user.roles;
            this.pDialog = true;
          }
        }, (err) => {
          if (err.response.data.response.error) {
            this.snackbar = true;
          }
          this.loading = false;
        }
      )
    }
  },
  mixins: [goTo]
}
</script>

<style>

</style>